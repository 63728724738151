import {post,get} from "./axios";
//用户登录
export const getLoginApi =(param)=>post('/login', param)
//退出登录
export const getLoginOutApi =(param)=>post('/logout', param)

//登录短信验证码
export const getCodeApi =(param)=>get('/index/get_login_SMS_code',param)
//手机号验证码登录
export const getLoginCodeApi =(param)=>post('/login/mobile',param)

//注册短信验证码
export const getRegisterCodeApi =(param)=>get('/index/get_register_SMS_code',param)
//注册账号
export const registerApi =(param)=>post('/index/register',param)

//忘记密码短信验证码
export const getForgetPassWordCodeApi =(param)=>get('/index/get_update_SMS_code',param)
//忘记密码（短信修改）
export const editForgetPassWordApi =(param)=>post('/index/smsUpdatePassword',param)
//修改昵称
export const editNickNameApi =(param)=>post('/index/smsUpdateName',param)
//修改密码（旧 新密码）
export const editPassWordApi =(param)=>post('/index/updatePassword',param)
//修改手机号发送验证码
export const getEditMobileCodeApi =(param)=>get('/index/get_update_mobile_code',param)
//提交修改的新手机号
export const editEditMobileApi =(param)=>post('/index/smsUpdateMobile',param)

//查询登录记录
export const getRecordApi =(param)=>post('/operInfo/getList',param)


//查询消息通知
export const getNewsApi =(param)=>post('/notice/list',param)
//删除消息通知
export const delNewsApi =(param)=>post('/notice/del',param)
//单条已读消息通知
export const readNewsApi =(param)=>post('/notice/read',param)
//全部已读
export const readAllNewsApi =(param)=>get('/notice/readall',param)

//场所查询
export const getGroupListApi=(param)=>get('/hotel/group/list',param);
//根据groupId查询场所
export const getGroupListByGroupIdApi=(param)=>get('/hotel/group/list/group_id',param);
//地区查询
export const getRegionListApi=(param)=>get('/info/get_region',param);
//添加场所
export const addPlacesApi =(param)=>post('/hotel/group/add',param)
//修改场所
export const editPlacesApi =(param)=>post('/hotel/group/update',param)
//场所续费
export const editRegistsApi =(param)=>post('/hotel/group/updateRegister',param)
//删除场所
export const delPlacesApi =(param)=>get('/hotel/group/delete',param)


//查询图片列表
export const getFileApi =(param)=>get('/file/list',param)
//删除图片
export const delFileApi =(param)=>get('/file/delete',param)

//查询左侧菜单列表
export const getHomeMenuApi =(param)=>post('/hotel/group/get_menu_by_group_user',param)



//参数配置-(type)
export const getSystemConfigApi =(param)=>post('/system/config/list',param)
//修改参数配置
export const editSystemConfigApi =(param)=>post('/system/config/update',param)

//保存版本更新记录
export const editApkVersionApi =(param)=>post('/apkVersion/update',param)
//查询版本更新记录
export const getApkVersionApi =(param)=>get('/apkVersion/getApkList',param)


//查询阿里短信模板
export const getSmsTemplateApi =(param)=>get('/sms/list',param)
//修改阿里短信模板
export const editSmsTemplateApi =(param)=>post('/sms/update',param)



//权限配置
//权限配置查询父级菜单
export const getMenuPowerByParentApi =(param)=>get('/system/menu/get_parent_menu_by_menu_id',param)
//添加权限菜单
export const addMenuPowerApi =(param)=>post('/system/menu/add_menu',param)
//修改权限菜单
export const editMenuPowerApi =(param)=>post('/system/menu/update_menu',param)
//删除权限菜单
export const delMenuPowerApi =(param)=>post('/system/menu/delete_menu',param)


//操作员管理
//查询用户
export const getSystemUserApi =(param)=>post('/system/user/list',param)
//修改用户
export const editSystemUserApi =(param)=>post('/system/user/update',param)
//添加用户
export const addSystemUserApi =(param)=>post('/system/user/add',param)

//查询系统资讯
export const getNewsListApi =(param)=>get('/system/news/list',param)
//添加系统资讯
export const addNewsListApi =(param)=>post('/system/news/add',param)
//修改系统资讯
export const editNewsListApi =(param)=>post('/system/news/update',param)
//删除系统资讯
export const delNewsListApi =(param)=>get('/system/news/delete',param)

//查询应用商店
export const getAppStoreApi =(param)=>get('/user_apk/list',param)
//添加or修改应用商店
export const addAppStoreApi =(param)=>post('/user_apk/save',param)
//删除应用商店
export const delAppStoreApi =(param)=>get('/user_apk/delete',param)
// 删除应用商店提示
export const delAppStoreApis =(param)=>post('/apkList/check',param)

//查询通用选项
export const getCurrencyApi =(param)=>get('/hotel/group/get_basic_info',param)
//修改通用选项
export const editCurrencyApi =(param)=>post('/hotel/group/update_basic_info',param)

//查询语言管理
export const getLanguageApi =(param)=>get('/language/getList',param)
//添加语言
export const addLanguageApi =(param)=>post('/language/add',param)
//修改语言
export const editLanguageApi =(param)=>post('/language/update',param)
//删除语言
export const delLanguageApi =(param)=>post('/language/del',param)
//恢复默认语言
export const defaultLanguageApi =(param)=>post('/language/def',param)

//查询启动广告
export const getBootSettingApi =(param)=>get('/bootAd/list',param)
//修改启动广告
export const editBootSettingApi =(param)=>post('/bootAd/update',param)


//查询场所角色
export const getGroupRoleApi =(param)=>get('/group/role/list',param)
//查询场所下角色菜单
export const getGroupRoleMenuApi =(param)=>get('/group/role/get_menu_by_role_id',param)
//添加场所角色
export const addGroupRoleApi =(param)=>post('/group/role/add',param)
//修改场所角色
export const editGroupRoleApi =(param)=>post('/group/role/update',param)
//删除场所角色
export const delGroupRoleApi =(param)=>get('/group/role/delete',param)

//查询场所员工
export const getGroupStaffApi =(param)=>get('/hotel/group/get_employee',param)
//添加场所员工
export const addGroupStaffApi =(param)=>post('/hotel/group/add_employee',param)
//修改场所员工
export const editGroupStaffApi =(param)=>post('/hotel/group/update_employee',param)
//删除场所员工
export const delGroupStaffApi =(param)=>get('/hotel/group/delete_employee',param)
//批量修改场所员工
export const batchEditGroupStaffApi =(param)=>post('/hotel/group/batch_update_employee',param)
//批量删除场所员工
export const batchDelGroupStaffApi =(param)=>post('/hotel/group/batch_delete_employee',param)

//查询终端
export const getTerminalApi =(param)=>get('/hotel/group/get_client',param)
//修改终端
export const editTerminalApi =(param)=>post('/hotel/group/update_client',param)
//删除终端
export const delTerminalApi =(param)=>get('/hotel/group/delete_client',param)
//批量修改终端
export const batchEditTerminalApi =(param)=>post('/hotel/group/batch_update_client',param)
//批量删除终端
export const batchDelTerminalAps =(param)=>post('/hotel/group/batch_delete_client',param)

//查询套餐组
export const getPackageGroupApi =(param)=>get('/packagegroup/list',param)
//添加套餐组
export const addPackageGroupApi =(param)=>post('/packagegroup/add',param)
//修改套餐组
export const editPackageGroupApi =(param)=>post('/packagegroup/update',param)
//删除套餐组
export const delPackageGroupApi =(param)=>post('/packagegroup/del',param)
//查询用户对应的套餐组（只显示底下有套餐的组）
export const getPackageGroupByIdApi =(param)=>get('/packagegroup/get_by_user_id',param)
//查询用户支付时的套餐
export const getPayPackageByUserIdApi =(param)=>get('/operpackge/get_by_user_id_when_the_payment',param)

//查询套餐
export const getPackageApi =(param)=>post('/operpackge/list',param)
//添加套餐
export const addPackageApi =(param)=>post('/operpackge/add',param)
//修改套餐
export const editPackageApi =(param)=>post('/operpackge/update',param)
//删除套餐
export const delPackageApi =(param)=>post('/operpackge/del',param)


//查询网络节目源节目组
export const getNetworkSourceGroupApi =(param)=>get('/live/live_group/list',param)
//添加节目组
export const addNetworkSourceGroupApi =(param)=>post('/live/live_group/add',param)
//修改节目组
export const editNetworkSourceGroupApi =(param)=>post('/live/live_group/update',param)
//删除节目组
export const delNetworkSourceGroupApi =(param)=>post('/live/live_group/delete',param)
//应用节目组
export const applyNetworkSourceGroupApi =(param)=>get('/live/live_group/apply',param)

//查询当前选中的组分享的用户集合
export const getGroupToShareUserListApi =(param)=>get('/live/live_group/get_share',param)
//共享节目组给用户
export const shareGroupApi =(param)=>post('/live/live_group/share',param)
//删除共享节目用户
export const delShareGroupApi =(param)=>post('/live/live_group/delete_share',param)

//查询节目源分类
export const getChannelCategoryApi =(param)=>get('/live/channel_type/list',param)
//添加分类
export const addChannelCategoryApi =(param)=>post('/live/channel_type/add',param)
//修改分类
export const editChannelCategoryApi =(param)=>post('/live/channel_type/update',param)
//删除分类
export const delChannelCategoryApi =(param)=>post('/live/channel_type/delete',param)

//查询节目源
export const getChannelApi =(param)=>get('/live/channel/list',param)
//添加节目源
export const addChannelApi =(param)=>post('/live/channel/add',param)
//修改节目源
export const editChannelApi =(param)=>post('/live/channel/update',param)
//删除节目源
export const delChannelApi =(param)=>post('/live/channel/delete',param)


//查询EPG
export const getEpgApi =(param)=>get('/live/epg/list',param)
//添加EPG
export const addEpgApi =(param)=>post('/live/epg/add',param)
//修改EPG
export const editEpgApi =(param)=>post('/live/epg/update',param)
//删除EPG
export const delEpgApi =(param)=>post('/live/epg/delete',param)

//查询推送
export const getPushApi =(param)=>post('/push/list',param)
//添加推送
export const addPushApi =(param)=>post('/push/add',param)
//修改推送
export const editPushApi =(param)=>post('/push/updatebyid',param)
//删除推送
export const delPushApi =(param)=>post('/push/delbyid',param)

//查询我的账务
export const getAccountsApi =(param)=>get('/bill/list',param)
//查询场所 终端 总数
export const getCountByUserApi =(param)=>get('/bill/count',param)


//查询商品分类
export const getMallCategoryApi =(param)=>get('/mall/getCategory',param)
//添加商品分类
export const addMallCategoryApi =(param)=>post('/mall/addCategory',param)
//修改商品分类
export const editMallCategoryApi =(param)=>post('/mall/updateCategory',param)
//删除商品分类
export const delMallCategoryApi =(param)=>post('/mall/delCategory',param)
//查询商品
export const getCommodityApi =(param)=>get('/mall/commodity/getCommodity',param)
//添加商品
export const addCommodityApi =(param)=>post('/mall/commodity/addCommodity',param)
//修改商品
export const editCommodityApi =(param)=>post('/mall/commodity/updateCommodity',param)
//删除商品
export const delCommodityApi =(param)=>post('/mall/commodity/delCommodity',param)

//查询公共景点库
export const getPublicSceneryApi =(param)=>post('/scenice/getListByName',param)
//查询公共景点库图片
export const getSceneryImgApi =(param)=>post('/scenice/getPicture',param)
//查询我的景点
export const getSceneryApi =(param)=>post('/scenice/getListByGroupId',param)
//添加我的景点
export const addSceneryApi =(param)=>post('/scenice/add',param)
//修改我的景点
export const editSceneryApi =(param)=>post('/scenice/update',param)
//删除我的景点
export const delSceneryApi =(param)=>post('/scenice/delByIds',param)

//查询送物列表
export const getLoanServiceApi =(param)=>get('/send/list',param)
//添加物品
export const addLoanServiceApi =(param)=>post('/send/add',param)
//修改物品
export const editLoanServiceApi =(param)=>post('/send/update',param)
//删除物品
export const delLoanServiceApi =(param)=>post('/send/del',param)


//支付宝充值
export const alipayRechargeApi =(param)=>post('/ali_pay/initiate_payment',param)
//微信充值
export const wechatRechargeApi =(param)=>post('/wx_pay/initiate_payment',param)
//按订单号查询支付状态
export const getPayRechargeStatusApi =(param)=>get('/accounting_order_form/get_by_order_form_id',param)



//查询应用列表
export const getApplicationListApi =(param)=>get('/apkList/getApkList',param)
//添加应用列表
export const addApplicationListApi =(param)=>post('/apkList/add',param)
//修改应用列表
export const editApplicationListApi =(param)=>post('/apkList/updateApkList',param)
//删除应用列表
export const delApplicationListApi =(param)=>post('/apkList/del',param)
//上传本地应用到应用列表
export const addUploadApplicationListApi =(param)=>post('/apkList/save',param)

//查询应用市场资源库
export const getApkMarketApi =(param)=>post('/apkList/getUserApkList',param)


//查询主题
export const getHomeThemeApi =(param)=>get('/theme/getList',param)
//添加主题
export const addHomeThemeApi =(param)=>post('/theme/add',param)
//修改主题
export const editHomeThemeApi =(param)=>post('/theme/update',param)
//删除主题
export const delHomeThemeApi =(param)=>post('/theme/del',param)
//应用主题
export const applicationHomeThemeApi =(param)=>post('/theme/setOn',param)


//查询欢迎界面
export const getBootInterfaceApi =(param)=>get('/welcome/get',param)
//修改欢迎界面
export const editBootInterfaceApi =(param)=>post('/welcome/update',param)


//查询主界面背景
export const getMainInterfaceBackgroundApi =(param)=>get('/background/get',param)
//查询主界面导航列表
export const getMainInterfaceNavigationApi =(param)=>get('/navigation/getList',param)
//添加导航列表
export const addMainInterfaceNavigationApi =(param)=>post('/navigation/add',param)
//删除导航列表
export const delMainInterfaceNavigationApi =(param)=>post('/navigation/del',param)
//修改主页
export const editMainInterfaceApi =(param)=>post('/batchModify/update',param)

//查询卡片列表
export const getMainInterfaceCardApi =(param)=>get('/card/getList',param)
//添加卡片
export const addMainInterfaceCardApi =(param)=>post('/card/add',param)
//删除卡片
export const delMainInterfaceCardApi =(param)=>post('/card/del',param)

//查询内容页列表
export const getContentPageApi =(param)=>get('/controller/getList',param)
//删除内容页
export const delContentPageApi =(param)=>get('/controller/delById',param)

//子界面模板4删除卡片
export const delTemplateCardApi =(param)=>post('/card/delById',param)
//子界面模板4批量保存
export const editTemplateCardApi =(param)=>post('/batchModify/addAndUpdate',param)

//查询模板
export const getTemplateApi =(param)=>get('/template/getTemplate',param)

//获取渐变色列表
export const getGradientListApi =(param)=>get('/gradient/getList',param)


//查询网关设备
export const getGatewayApi =(param)=>get('/live/gateway/list',param)