import axios from "axios";
import {
    MessageBox,
    Message
} from "element-ui";
import router from '../router/index'
import Aes from '../utils/aes'
import store from '../store'

// create an axios instance
const instance = axios.create({
    // baseURL: store.state.fileUrl, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000, // request timeout
    headers: {
        "Content-Type": "application/json",
        "data-type":"json"
    }
})

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    config.headers.accessToken=store.state.user.authorization;
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    if (response.status == 200) {
        const res = JSON.parse(Aes.decode(response.data));
        console.log(res);
        if (res.type == "success") {
            return res;
        } else {
            Message({
                message: res.msg,
                type: 'error'
            })
        }
    } else {
        return response;
    }
    // 对响应数据解密并转JSON
    // return JSON.parse(Aes.decode(response.data));
}, function (error) {
    // 对响应错误做点什么
    if (error.response.status) {
        switch (error.response.status) {
            // 401: 未登录
             // 登录过期对用户进行提示
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面
            // 未登录则跳转登录页面，并携带当前页面的路径
            // 在登录成功后返回当前页面，这一步需要在登录页操作。
            case 401:
                router.push('/notFound')
                break;

                // 1002 没有权限
            case 1002:
                MessageBox.alert('没有权限，请关闭重新进入。', '提示', {
                    confirmButtonText: '确定',
                    callback: () => {
                        router.push('/login')
                        // 清除token
                    }
                })

                break;

                // 404请求不存在
            case 404:
                console.log("您访问的网页不存在。");
                break;
                // 其他错误，直接抛出错误提示
            default:
                console.log("其他错误");
        }
        // return error.response;
    }
});

/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
 export const get=(url, params)=>{    
    return new Promise((resolve, reject) =>{        
        instance.get(url, {            
            params: params        
        })        
        .then(res => {            
            resolve(res);        
        })        
        .catch(err => {            
            reject(err)        
        })    
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
 export const post=(url, params)=>{    
    return new Promise((resolve, reject) => {
        instance.post(url, Aes.encrypt(JSON.stringify(params)))        
        .then(res => {            
            resolve(res);        
        })        
        .catch(err => {            
            reject(err)        
        })    
    });
}
export default instance