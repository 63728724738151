import CryptoJS from "crypto-js";

export default {
    //解密
    decode(word) {
        var key= CryptoJS.enc.Utf8.parse('1111111111111111')
        var decrypt =CryptoJS.AES.decrypt(word,key,{
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        })
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    },
      //加密
      encrypt(word, keyStr) {
        keyStr = keyStr ? keyStr : '1111111111111111'; //判断是否存在ksy，不存在就用定义好的key
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var srcs = CryptoJS.enc.Utf8.parse(word);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return encrypted.toString();
    },

}